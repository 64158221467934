import React from "react";
import "./Footer.scss";
import facebook1 from "../../Images/facebook1.webp";
import instagram from "../../Images/instagram.webp";
import linkedin from "../../Images/linkedin1.webp";
import telegram from "../../Images/telegramlogo.webp";
import twiter from "../../Images/twitter1.webp";
import youtube from "../../Images/youtube1.webp";
import whatsapp from "../../Images/whatsapp.png";
const FooterEl = () => {
  return (
    <div className="footerContainer">
      <div className="subContainer">
        <text className="footerAppname">Chat&money</text>
        <text className="footerAppname-com">chatandmoney.com / C&M</text>
      </div>

      <div className="linkIcons">
        <a
          href="https://www.facebook.com/profile.php?id=61561833271265"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={facebook1}
            style={{ borderRadius: "10px" }}
            alt="Logo"
            width={30}
            height={30}
          />
        </a>
        <a
          href="https://whatsapp.com/channel/0029VacXbB06hENyBuQik21v"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={whatsapp}
            style={{
              borderRadius: "90px",
              backgroundColor: "#fff",
              padding: "4px",
            }}
            alt="Logo"
            width={25}
            height={25}
          />
        </a>
        <a
          href="    https://www.youtube.com/@ChatandMoney"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={youtube}
            style={{ borderRadius: "10px" }}
            alt="Logo"
            width={30}
            height={30}
          />
        </a>
        <a
          href="https://www.instagram.com/chatandmoney?igsh=OXVmMmVyNjZremI0&utm_source=qr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={instagram}
            style={{ borderRadius: "10px" }}
            alt="Logo"
            width={30}
            height={30}
          />
        </a>
      </div>
      <div className="textContainer">
      <text
        style={{
          color: "#fff",
          marginTop: "20px",
          fontStyle: "italic",
          fontWeight: 500,
        }}
      >
        This game may be habit-forming or financially risky.
      </text>
      <text
        style={{
          color: "#fff",
          marginTop: "0px",
          fontStyle: "italic",
          fontWeight: 500,
        }}
      >
        Play responsibly at your own risk.
      </text>
      <text style={{ color: "#fff", marginTop: "20px" }}>
        Regd Ofiice Add : 40/45 'OR' 556 SHIV SHAKTI NAGAR
      </text>
      <text style={{ color: "#fff", marginTop: "2px" }}>
        MEERUT, 250002 (UTTAR PRADESH) INDIA
      </text>
      <text style={{ color: "#fff", marginTop: "2px" }}>
        Design & Developed by 🤍
      </text>
      </div>
      <div className="footerBase"></div>
    </div>
  );
};

export default FooterEl;
