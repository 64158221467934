import React from "react";
import "./FifthCompo.scss";
import jump from "../../Images/web comp.png";

const FifthCompo = () => {
  return (
    <div className="fifthComponentsContainer">
      <img src={jump} className="fifthImage" alt="Logo" />
      <div className="fithtextContainer">
        <text className="fifthcomptext">{"}"} Received Quest</text>
      </div>
    </div>
  );
};

export default FifthCompo;
