import React from "react";
import QRdream11 from "../../Images/chatandmoney.png";
import "./DownloadBox.scss";
import { Link } from "react-router-dom";

const DownloadBox = () => {
  return (
    <div className="Wrapper2">
      <div className="QrText">
        <span className="text1">Download App</span>
        <span className="text2">Get the app and win more</span>
      </div>
      <img className="QRImage" src={QRdream11} alt="Logo" />

      {/* <Upload /> */}
    </div>
  );
};

export default DownloadBox;
