import React from "react";
import "./FourthCompo.scss";
import jump from "../../Images/12.3.png";

const FourthCompo = () => {
  return (
    <div className="fourthComponentsContainer">
      <div>
        <text className="fourthcomptext">You send quest {"{"}</text>
        {/* <text className="fourthcomptext">receive Quest</text> */}
      </div>
      <img src={jump} className="fourthImage" alt="Logo" />
    </div>
  );
};

export default FourthCompo;
