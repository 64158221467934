import React from "react";
import "./ThirdCompo.scss";
import jump from "../../Images/skills.png";

const ThirdCompo = () => {
  return (
    <div className="thirdComponentsContainer">
      <img src={jump} className="thirdImage" alt="Logo" />
      <div className="textContainerThird">
        <div className="thirdcomptext">Show your</div>
        <div className="thirdcomptext">skills to World</div>
      </div>
    </div>
  );
};

export default ThirdCompo;
