import React, { useState,useEffect } from "react";
import androidIc from "../../Images/android-white.png";
import appstoreWhite from "../../Images/apple.png";
import QrCode from "../../Images/qr-code2.png";
import "./DownloadAndroid.scss";
import "./FileDownloadButtonAndroid.scss";

const DownloadAndroid = () => {
 
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "https://firebasestorage.googleapis.com/v0/b/chatandmoney-3033.appspot.com/o/uploads%2Fchatandmoney.apk?alt=media&token=09e9ead0-d43b-4051-985c-9ff439e0bf13" ;
    link.download = "Chatandmoney.apk";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); 
  };



return (
    <div className="androidWrapper">
      {/* android box componenet */}

      <div className="androidBox" onClick={handleDownload}>
        <div className="androidIconContainer">
          <img src={androidIc} className="androidIcon" alt="Logo" />
          <div className="buttonAndroid">
            <button className="androidTextlogo"> Android app</button>
          </div>
        </div>
      </div>
      {/* ios box componenet */}
      <div className="androidBox">
        <div className="androidIconContainer">
          <img src={appstoreWhite} className="androidIcon" alt="Logo" />
          <div className="buttonAndroid">
            <button className="androidTextlogo">iOS app</button>
          </div>
        </div>
        <div className="buttonComingsoon">
          <button className="androidTextlogoComingsoon">Coming Soon*</button>
        </div>
      </div>
      {/* Web box componenet */}

      <img src={QrCode} className="QrCode" alt="Logo" />
    </div>
  );
};

export default DownloadAndroid;
