import React from "react";
import "./SecondComponent.scss";
import jump from "../../Images/jump.png";

const SecondComponent = () => {
  return (
    <div className="secondComponentsContainer">
      <div className="textContainerSecond"> 
        <div className="seccomptext">Jump into the </div>
        <div className="seccomptext">chat game for</div>
        <div className="seccomptext"> Quizzzzzes...</div>
      </div>
      <img src={jump} className="secondImage" alt="Logo" />
    </div>
  );
};

export default SecondComponent;
